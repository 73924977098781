import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';
import Contact from './components/Contact';
import About from './components/About';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import LandingPage from './components/LandingPage'
import './App.css';
import Dagens from './components/Dagens';

const App = () => {
  return (
    <Router>
      <div className="wrapper">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/lunch" element={<Dagens />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
