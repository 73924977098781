const dagensMeny = {
    måndag: {
      visName: "Måndag",
      price: 109,
      items: [
        { name: "KYCKLING KORMA", description: "Delikat kycklinggryta tillagad med yoghurt, mandel, kokos, smör och grädde" },
        { name: "NÖTKÖTT CURRY", description: "Nötkött i currysås med färsk tomat" },
        { name: "LAMM DOPIAZAH 🌶️", description: "Lammgryta med färsk tomat, lök, paprika i fyllig kryddig sås" },
        { name: "ALOO PALAK (Vegetarisk rätt)", description: "Tomat, indiska kryddor, linser och smör" },
        { name: "SABNAM CURRY (Vegetarisk rätt)", description: "Potatis blandad med grönsaker och currysås" }
      ]
    },
    tisdag: {
      visName: "Tisdag",
      price: 109,
      items: [
        { name: "KYCKLING SPENAT", description: "Kycklingfilé och spenat i currysås med färsk tomat, ingefära, garam masala" },
        { name: "BIFF KORMA", description: "Delikat biffgryta tillagad med yoghurt, mandel, kokos, smör och grädde" },
        { name: "LAMM JALFREZI", description: "Lammkött tillagat med grönsaker i en kryddig, syrlig sås med chili och aromatiska kryddor" },
        { name: "TARKA DAAL", description: "Kokta linser med smör och indiska kryddor" },
        { name: "ALO GOBI", description: "Potatis och blomkål tillagad på indiskt vis" }
      ]
    },
    onsdag: {
      visName: "Onsdag",
      price: 109,
      items: [
        { name: "KYCKLING TIKKA MASALA", description: "Grillad kycklingfilé i gryta tillagad med crème fraiche, tomat och smörfrästa kryddor" },
        { name: "BIFF SAMBAL🌶️", description: "Biffgryta tillagad med speciella Indiska kryddor, söt-sur-stark smak" },
        { name: "LAMM KORMA", description: "Delikat lammgryta tillagad med grädde, mandelnötter och smör" },
        { name: "CHANA MASALA", description: "Potatis fylld med russin, grönsaker och indisk gräddsås" },
        { name: "MIX SABZI", description: "Blandade grönsaker i sås med klassiska indiska kryddor" }
      ]
    },
    torsdag: {
      visName: "Torsdag",
      price: 109,
      items: [
        { name: "PALAK PANIR", description: "Indisk hemgjord ost stekt och kokt i currysås med spenat" },
        { name: "ALO GOBI", description: "Potatis och blomkål tillagad på indiskt vis" },
        { name: "KYCKLING CURRY", description: "Kyckling i currysås med färsk tomat" },
        { name: "RÄKOR KORMA", description: "Delikata räkor i gryta tillagad med yoghurt, nötter, smör och grädde" },
        { name: "BIFF DOPIAZAH 🌶️", description: "Lammgryta med färsk tomat, lök, paprika i tjock kryddig sås" }
      ]
    },
    fredag: {
      visName: "Fredag",
      price: 109,
      items: [
        { name: "KYCKLING SAMBAL🌶️", description: "Kycklinggryta tillagad med speciella Indiska kryddor, söt-sur-stark smak" },
        { name: "LAMM SPENAT", description: "Lamm tillagad med spenat och färska kryddor" },
        { name: "NÖTKÖTT KARAHI🌶️🌶️", description: "Nötkött tillagad med traditionella kryddor, mynta, färska tomater, grön och röd paprika och koriander" },
        { name: "MIX SABZI", description: "Blandade grönsaker i sås med klassiska indiska kryddor" },
        { name: "DAAL SAAG", description: "Röd linser, spenat, vitlök, och space kryddor" }
      ]
    }
  };
  
  export default dagensMeny;
  