import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <section id="about">
    <div className="aboutText">
      <h1>Om oss</h1>
      <p>
        <strong>Välkommen till Tanjay Tropiskt Kök – där smaker från tropikerna möter passionen för matlagning!</strong>
      </p>
      <p>
        På Tanjay Tropiskt Kök strävar vi efter att skapa en unik matupplevelse inspirerad av de rika och varierade köken från tropiska regioner. Vårt mål är att ta med dig på en kulinarisk resa fylld av spännande smaker, färska råvaror och kärlek till det exotiska.
      </p>
      
      <h2>Vår historia</h2>
      <p>
        Tanjay Tropiskt Kök grundades med en vision att föra samman det bästa av tropisk matlagning i en miljö som är lika varm och välkomnande som de platser som inspirerar oss. Från de kryddiga curryrätterna i Asien till de smakrika marinaderna från Karibien – varje rätt på vår meny är noggrant skapad för att ge dig en genuin smakupplevelse.
      </p>
      
      <h2>Vad vi erbjuder</h2>
      <ul>
        <li><strong>En smak av tropikerna:</strong> Våra rätter är en hyllning till tropisk matlagning med influenser från bland annat Indien, Sri Lanka, Karibien och Sydostasien.</li>
        <li><strong>Färska råvaror:</strong> Vi använder endast de bästa ingredienserna för att säkerställa att varje tugga är full av smak.</li>
        <li><strong>Vegetariska och veganska alternativ:</strong> Vi har ett brett utbud av rätter som passar olika kostbehov, för att alla ska känna sig välkomna hos oss.</li>
        <li><strong>En atmosfär som tar dig bort:</strong> Vår restaurang är inredd för att ge en känsla av tropisk semester – med gröna inslag, varma färger och en vänlig atmosfär.</li>
      </ul>
      
      <h2>Vårt löfte</h2>
      <p>
        Vi strävar alltid efter att erbjuda mat och service av högsta kvalitet. Hos oss är varje gäst en del av vår Tanjay-familj, och vi vill att du ska känna dig lika uppskattad som om du vore hemma.
      </p>
      
      <h2>Kom och upplev oss</h2>
      <p>
        Vi ser fram emot att få välkomna dig och dina nära och kära till Tanjay Tropiskt Kök. Kom och upplev smakerna, dofterna och den varma atmosfären – en plats där varje måltid blir ett minne för livet.
      </p>
      
      <p>
        <strong>🌴 Välkommen till Tanjay Tropiskt Kök – En smak av paradiset!</strong>
      </p>
    </div>
    </section>
  );
};

export default About;
