const menuData = {
  forratter: {
    visName: "Förrätter",
    items: [
    {
      id: 1,
      name: "PAPADAM",
      description: "2 st frasiga bröd (som stora chips) med mango chutney",
      price: 29,
    },
    {
      id: 2,
      name: "SAMOSA VEG/KÖTT",
      description: "Friterade grönsakspiroger, serveras med yoghurtsås och sallad",
      price: "39/49",
    },
    {
      id: 3,
      name: "PAKORA VEG",
      description: "Friterade grönsaksbollar med kikärtsmjöl, serveras med yoghurtsås och sallad",
      price: 39,
    },
    {
      id: 4,
      name: "VÅRRULLE",
      description: "Friterade grönsakspiroger, serveras med yoghurtsås och sallad",
      price: 39,
    },
    {
      id: 5,
      name: "KYCKLINGVINGAR",
      description: "Marinerade i soya, tandoori masala och vitlök",
      price: 49,
    },
    {
      id: 5,
      name: "VADAI",
      description: "Friterade linsbollar serveras med lankesisk kokosröra",
      price: 29,
    },
  ]
},
  brod: {
    visName: "Bröd",
    items:[
    {
      id: 7,
      name: "CHAPATI",
      description: "Indiskt tunnbröd gjort på rågmjöl eller tandoori roti",
      price: 30,
    },
    {
      id: 8,
      name: "ALOO PARATHA",
      description: "Smörstekt bröd med inbakad potatis",
      price: 39,
    },
  ]},
  kycklingRatter: {
    visName: "Kycklingrätter",
    items:[
    {
      id: 9,
      name: "KYCKLING CURRY",
      description: "Kyckling i currysås med färsk tomat",
      price: 149,
    },
    {
      id: 10,
      name: "KYCKLING TIKKA MASALA",
      description: "Grillad kycklingfilé i gryta tillagad med crème fraiche, tomat och smörfrästa kryddor",
      price: 159,
    },
    {
      id: 11,
      name: "KYCKLING KORMA",
      description: "Delikat kycklinggryta tillagad med yoghurt, mandel, kokos, smör och grädde",
      price: 159,
    },
    {
      id: 13,
      name: "KYCKLING KARAHI🌶️",
      description: "Lerugnsgrillad kycklingfilé tillagad med färska tomater, grön, röd paprika, koriander och färsk mynta",
      price: 159,
    },
    {
      id: 14,
      name: "KYCKLING VINDALOO🌶️🌶️🌶️",
      description: "Kyckling med stark sås med färsk chili och tomatpure",
      price: 159,
    },
    {
      id: 15,
      name: "KYCKLING DOPIAZAH 🌶️",
      description: "Kycklinggryta med färsk tomat, lök, paprika i fyllig kryddig sås",
      price: 149,
    },
    {
      id: 16,
      name: "KYCKLING SPENAT",
      description: "Kycklingfilé och spenat i currysås med färsk tomat, ingefära, garam masala",
      price: 159,
    },
    {
      id: 17,
      name: "KYCKLING BALTI🌶️🌶️",
      description: "Grillad kycklingfilé tillagad med söt-sur stark baltisås med tomat, paprika och lök",
      price: 159,
    },
    {
      id: 18,
      name: "KYCKLING SAMBAL🌶️",
      description: "Kycklinggryta tillagad med speciella Indiska kryddor, söt-sur-stark smak",
      price: 159,
    },
    {
      id: 19,
      name: "KYCKLING MADRAS🌶️",
      description: "Stekt kyckling marinerad med yoghurt och olika kryddor, färsk koriander, stark chili och kokos",
      price: 159,
    }
  ]
},
  tanSizlar: {
    visName: "Tandoori Sizlar",
    items:[
    {
      id: 20,
      name: "KYCKLING TANDOORI SIZLAR",
      description: "Kycklingklubbor marinerade i traditionell tandoori masala, tillagad på spett i tandoori",
      price: 149,
    },
    {
      id: 21,
      name: "KYCKLING SIZLAR",
      description: "Kycklingfilé marinerad i yoghurt med aromatiska kryddor",
      price: 159,
    },
    {
      id: 22,
      name: "GARLIC KYCKLING SIZLAR",
      description: "Kycklingfilé marinerad i färsk vitlök",
      price: 159,
    },
    {
      id: 23,
      name: "LAMM SIZLAR",
      description: "Lammfilé marinerad i yoghurt med traditionell tandoori masala",
      price: 189,
    },
    {
      id: 24,
      name: "MIX SIZLAR",
      description: "Kombination av chicken sizlar, lamm sizlar, garlic chicken sizlar och seekh kebab",
      price: 189,
    },
    {
      id: 25,
      name: "SALMON TIKKA SIZLAR",
      description: "Laxfilé marinerad i yoghurt, ingefära, vitlök, örter, citron och garam masala",
      price: 179,
    },
  ]},

  Lamm: {
    visName: "Lamm rätter",
    items:[
    { name: "LAMM CURRY", price: 149, description: "Lamm i currysås med färsk tomat" },
    { name: "LAMM BUTTER MASALA", price: 169, description: "Tandoorigrillad lammfilé i gryta tillagad med crème fraiche, tomat och smörstarka kryddor" },
    { name: "LAMM KORMA", price: 159, description: "Delikat lammgryta tillagad med grädde, mandelnötter och smör" },
    { name: "LAMM KARAHI", price: 159, description: "Lammstek tillagad med färska tomater, paprika, mynta och koriander", "spicy": "🌶️" },
    { name: "LAMM SPENAT", price: 159, description: "Lammrätter tillagad med spenat och färska kryddor" },
    { name: "LAMM BALTI", price: 159, description: "Grillad lammfilé tillagad med sur och stark baltisås och med tomat, paprika och lök", "spicy": "🌶️" },
    { name: "LAMM SAMBAL", price: 159, description: "Lammgryta tillagad med speciella indiska kryddor, söt-sur stark smak", "spicy": "🌶️" },
    { name: "LAMM VINDALOO", price: 159, description: "Grillad lammfilé tillagad med sur och stark baltisås och med tomat, paprika och lök", "spicy": "🌶️🌶️🌶️" },
    { name: "ROGHAN JOSH", price: 149, description: "Lammstek gryta tillagad med sesam, yoghurtsås och blandad med färsk koriander" }
  ]},
  Biryani: {
    visName: "Briyani",
    items:[
    {
      name: "JHINGA BIRYANI",
      price: 149,
      description: "Små räkor blandade med indiskt basmatiris, färsk koriander, färsk mynta, stekt lök smaksatt med saffran"
    },
    {
      name: "KYCKLING BIRYANI",
      price: 159,
      description: "Kyckling och ris smaksatt med saffran och en blandning av olika kryddor"
    },
    {
      name: "VEGETARISK BIRYANI",
      price: 149,
      description: "Grönsaker blandade med indiskt basmatiris, färsk koriander, mynta smaksatt med saffran"
    },
    {
      name: "LAMM BIRYANI",
      price: 159,
      description: "Lamm och ris smaksatt med saffran och en blandning av olika kryddor"
    }
  ]},
  fisk_och_rakor: {
    visName: "Fisk och räkor",
    items:[
    {
      name: "FISK CURRY",
      price: 149,
      description: "Laxfilé tillagad i currysås"
    },
    {
      name: "FISK KARAHI",
      price: 159,
      description: "Laxfilé serveras med tomat, grön och röd paprika, mynta, färsk koriander och chili"
    },
    {
      name: "RÄKOR CURRY",
      price: 149,
      description: "Små räkor i currysås med färsk tomat"
    },
    {
      name: "RÄKOR KORMA",
      price: 149,
      description: "Delikata räkor i gryta tillagad med yoghurt, nötter, smör och grädde"
    },
    {
      name: "RÄKOR SPENAT",
      price: 149,
      description: "Räkor tillagad med bladspenat och färska kryddor"
    },
    {
      name: "RÄKOR SABZI",
      price: 149,
      description: "Räkor med grönsaker och färska kryddor"
    },
    {
      name: "RÄKOR VINDALOO",
      price: 149,
      description: "Små räkor med extra stark sås, färsk tomat och lök"
    }
  ]},
  Veg: {
    visName: "Vegetariska rätter",
    items:[
    {
      name: "DAAL SAAG",
      price: 149,
      description: "Röd linser, spenat, vitlök, och orientaliska kryddor"
    },
    {
      name: "ALO GOBI",
      price: 149,
      description: "Potatis och blomkål tillagad på indiskt vis"
    },
    {
      name: "MIX SABZI ",
      price: 129,
      description: "Blandade grönsaker i sås med klassiska indiska kryddor"
    },
    {
      name: "PALAK PANIR",
      price: 149,
      description: "Indisk hemgjord ost stekt och kokt i currysås med spenat"
    },
    {
      name: "TARKA DAAL",
      price: 139,
      description: "Kokta linser med smör och indiska kryddor"
    },
    {
      name: "PANIR BUTTER MASALA",
      price: 159,
      description: "Smörstekt hemgjord ost med yoghurt och tikka masalasås"
    },
    {
      name: "CHANA MASALA",
      price: 149,
      description: "Potatis fylld med russin, grönsaker och indisk gräddsås"
    },
    {
      name: "MALAI KOFTA",
      price: 149,
      description: "Nötter, russin, grönsaker och indisk gräddsås"
    },
    {
      name: "SABNAM CURRY",
      price: 149,
      description: "Potatis blandad med grönsaker och currysås"
    },
    {
      name: "ALOO PALAK",
      price: 149,
      description: "Tomat, indiska kryddor, linser och smör"
    },
    
  ]},

  notkott: {
    visName: "NötKött",
    items:[
    {
      name: "NÖTKÖTT CURRY",
      price: 149,
      description: "Nötkött i currysås med färsk tomat"
    },
    {
      name: "NÖTKÖTT SPENAT",
      price: 149,
      description: "Nötkötträtt tillagad med spenat och färska kryddor"
    },
    {
      name: "ACHAR GOSTO",
      price: 159,
      description: "Biffgryta lagad med indiska achar masala med yoghurt och färsk koriander"
    },
    {
      name: "NÖTKÖTT VINDALOO",
      price: 159,
      description: "Nötkött med yoghurt och extra stark sås med färsk tomat"
    },
    {
      name: "NÖTKÖTT KARAHI",
      price: 159,
      description: "Nötkött tillagad med traditionella kryddor, mynta, färska tomater, grön och röd paprika och koriander"
    }
  ]},
  extra: {
    visName: "Extra",
    items:[
    {
      name: "BARNPORTION",
      price: 120,
      description: "Välj mellan kyckling korma, tikka masala, vegetarisk curry, tandoori klubba, chicken nuggets och pommes"
    },
    {
      name: "VITSÅS",
      price: 30,
      description: "Majonnäs, vitlök och socker"
    },
    {
      name: "RAITA",
      price: 30,
      description: "Gurka, tomat, färsk koriander och mynta blandad i yoghurtssås"
    },
    {
      name: "PICKEL",
      price: 30,
      description: "Välj mellan mix pickel, chili pickel och mango pickel"
    },
    {
      name: "MANGO CHUTNEY",
      price: 30,
      description: "En söt-syrlig indisk sås gjord på mogen mango, kryddor och en hint av chili, perfekt som tillbehör till både varma och kalla rätter."
    },
    {
      name: "EXTRA RIS",
      price: 30,
      description: ""
    }
  ]},
  Exotiska: {
    visName: "Exotiska drycker",
    items:[
    {
      name: "SÖT LASSI",
      price: 35,
      description: "En krämig och uppfriskande yoghurtdryck sötad med socker och smaksatt med rosvatten eller kardemumma"  


    },
    {
      name: "SALT LASSI",
      price: 35,
      description: "En syrlig yoghurtdryck kryddad med salt, spiskummin och fräscha örter för en balanserad smak"  


    },
    {
      name: "MANGO LASSI",
      price: 35,
      description: "En krämig och uppfriskande yoghurtdryck mixad med söt mango och en touch av kardemumma för en exotisk smakupplevelse"
    },
    {
      name: "CHAI",
      price: 35,
      description: "En aromatisk och värmande indisk teblandning, tillagad med svart te, mjölk, socker och en perfekt balans av kryddor som kardemumma, kanel och ingefära"
    }
  ]}, 
  lask : {
    visName: "Kolsyrade drycker",
    items: [
      {
        name: "Coca-Cola",
        price: 19,
        description: ""
      },
      {
        name: "Coca-Cola zero",
        price: 19,
        description: ""
      },
      {
        name: "Fanta Exotic",
        price: 19,
        description: ""
      },
      {
        name: "Fanta Exotic zero",
        price: 19,
        description: ""
      },
      {
        name: "Ramlösa naturell",
        price: 19,
        description: ""
      },
      {
        name: "Ramlösa citrus",
        price: 19,
        description: ""
      },
      {
        name: "Trocadero zero",
        price: 19,
        description: ""
      }
    ]
  },  
  lankes:{
    visName: "Lankesiska Rätter",
    items: [
      {
        name: "Kottu Roti",
        price: 135,
        description: "Hackad roti, grönsaker och ditt val av kyckling eller tofu, wokat med aromatiska kryddor och kokosolja."
      },
      {
        name: "Parippu (Dhal Curry)",
        price: 120,
        description: "En len och krämig linssoppa kokad med kokosmjölk, curryblad och milda kryddor. Serveras med färsk roti."
      },
      {
        name: "Chicken Curry (Kukul Mas Curry)",
        price: 145,
        description: "En mustig kycklinggryta tillagad med kokosmjölk, kardemumma och curryblad. Serveras med ångat ris eller string hoppers."
      },
      {
        name: "Hoppers (Appa)",
        price: 120,
        description: "Traditionella lankesiska pannkakor formade som skålar, med en krispig kant och en mjuk mitt. Serveras med ägg eller curry."
      },
      {
        name: "String Hoppers (Idiyappam)",
        price: 130,
        description: "Mjuka nudelliknande bröd som ångas och serveras med dhal, kycklingcurry eller en syrlig chutney."
      },
      {
        name: "Coconut Roti med Lunu Miris",
        price: 115,
        description: "Mjuka roti gjorda med färsk kokos och serverade med en kryddig chili-lökröra."
      }
    ]}
    
};

export default menuData;
