import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  return (
    <header>
      <div className="restaurant-name">
      <img src="/Images/LogoWithTextColor.png" alt="Tanjay Logo" className='headerLogo'/>
      </div>
      <nav>
        <ul>
          <li><Link to="/">Hem </Link></li>
          <li><Link to="/menu">Meny </Link></li>
          <li><Link to="/lunch">Lunch </Link></li>
          <li><Link to="/about">Om Oss </Link></li>
          {/* <li><Link to="/blog">Blog </Link></li> */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
