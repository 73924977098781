import React from 'react';
import '../styles/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the FontAwesomeIcon component
import { faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons'; // Import specific icons


const Footer = () => {
  return (
    <footer>
      
      <div className='social-buttons'>
        <a href="https://www.instagram.com/tanjay_tk/" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </button>
        </a>
        <a href="https://www.facebook.com/share/1ANPG1mcVU/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </button>
        </a>
        <a href="https://www.tiktok.com/@tanjay602?_t=8qXhfb4vczg&_r=1" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faTiktok} size="2x" />
          </button>
        </a>
        <a href="https://www.youtube.com/channel/UChlkrTPGgj-B7Tw4e2N1C4A" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </button>
        </a>
      </div>

      <p>&copy; 2024 Tanjay Tropiskt Kök</p>
    </footer>
  );
};

export default Footer;