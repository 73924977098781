import React, { useState } from "react";
import dagensMeny from "../data/dagensMeny";
import '../styles/Dagens.css'; // Import the CSS file

const Dagenslunch = () => {
  // Hämta dagens veckodag
  const today = new Date();
  const weekdayNames = [
    "söndag",
    "måndag",
    "tisdag",
    "onsdag",
    "torsdag",
    "fredag",
    "lördag",
  ];
  const currentDay = weekdayNames[today.getDay()];

  // Om det är lördag eller söndag, visa måndagens meny som standard
  const defaultDay = currentDay === "lördag" || currentDay === "söndag" ? "måndag" : currentDay;

  // State för att hålla koll på den valda dagen
  const [selectedDay, setSelectedDay] = useState(defaultDay);

  // Hämta menydata för den valda dagen
  const menuForSelectedDay = dagensMeny[selectedDay];

  return (
    <section className="dagens-meny">
      <div className="container">
        <h2>Lunch Meny</h2>
        <p>Lunch är mellan kl 11:00 till kl 14:00 på vardagar</p>
        <div className="day-buttons">
          {weekdayNames
            .filter((day) => day !== "lördag" && day !== "söndag") // Exkludera lördag och söndag från knappar
            .map((day) => (
              <button
                key={day}
                className={`day-button ${day === selectedDay ? "active" : ""}`}
                onClick={() => setSelectedDay(day)}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </button>
            ))}
        </div>
        <div className="menu-content">
          <h3>{menuForSelectedDay?.visName || "Ingen meny idag"}</h3>
          {menuForSelectedDay ? (
            <>
              <p className="price">Pris: {menuForSelectedDay.price} SEK</p>
              <ul className="menu-items">
                {menuForSelectedDay.items.map((item, index) => (
                  <li key={index} className="menu-item">
                    <h4>{item.name}</h4>
                    <p>{item.description}</p>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p>Ingen meny är tillgänglig för denna dag.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Dagenslunch;
